<template>
  <v-container class="fill-height" fluid data-cy="reset-password-page">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <ResetPassword
          v-if="authMethod == 'internal'"
          :token="token"
          :email="email"
        />
        <ResetPasswordCas
          v-if="authMethod == 'CAS3'"
          :token="token"
          :email="email"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ResetPassword from "@/modules/auth/components/ResetPassword.vue";
import ResetPasswordCas from "@/modules/auth/components/ResetPasswordCas.vue";

export default {
  name: "ResetPasswordPage",
  components: {
    ResetPassword,
    ResetPasswordCas,
  },
  computed: {
    authMethod() {
      return this.$store.getters["config/getAuthMethod"];
    },
    token() {
      let token = "";
      if (this.$route.query && this.$route.query.token) {
        token = this.$route.query.token.toString();
      }
      return token;
    },
    email() {
      let email = "";
      if (this.$route.query && this.$route.query.email) {
        email = this.$route.query.email.toString();
      }
      return email;
    },
  },
};
</script>
